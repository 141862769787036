import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Email from "./Email";
import Checkout from "./Checkout";
import AcceptInvitation from "./AcceptInvitation";
import { Box, Button, Container, Paper } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../src/authconfig";
import AddOnOrder from "./component/header/AddOnOrder";
import Home from "./component/Home/Home";
import Header from "./component/header/Header";
import AddOnOrders from "./component/addOnOrders/AddOnOrders";
import Checkouts from "./component/Checkouts";

const theme = createTheme();
const mdTheme = createTheme();

export default function App() {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  // const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setLoggedIn(isAuthenticated);

    if (
      !isAuthenticated &&
      window.location.pathname.includes("/addon-order/")
    ) {
      const addOnOrderId = window.location.pathname.split("/addon-order/")?.[1];
      localStorage.setItem("addOnOrderId", addOnOrderId);
    }
  }, [isAuthenticated]);

  // const toggleDrawer = () => {
  //   setOpen(!open);
  // };

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response: any) => {
          localStorage.setItem("token", response?.idToken);
        })
        .catch((e: any) => {});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then((res: any) => {})
      .catch((error: any) => {});
  };

  if (!loggedIn) {
    return (
      <ThemeProvider theme={mdTheme}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper
              sx={{
                p: 2,
              }}
            >
              <Typography>
                Du må logge inn for å bruke denne applikasjonen.
              </Typography>
              <Button
                // variant="secondary"
                className="ml-auto"
                onClick={() => handleLogin()}
              >
                {" "}
                Logg inn
              </Button>
              <br />
            </Paper>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <>
      {token ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />

          <BrowserRouter>
            <Routes>
              {/* <Route path="/checkout" element={<Checkout />} /> */}
              <Route path="/" element={<Home />} />
              <Route path="/email" element={<Email />} />
              <Route
                path="/accept-invitation/"
                element={<AcceptInvitation />}
              />
              <Route path="/addon-order" element={<AddOnOrder />} />
              <Route path="/addon-order/:id" element={<AddOnOrder />} />
              <Route path="/addon-orders/:id" element={<AddOnOrders />} />
              <Route path="/checkout" element={<Checkouts />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      ) : (
        <>
          <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography>
                    Du må logge inn for å bruke denne applikasjonen.
                  </Typography>
                  <Button
                    // variant="secondary"
                    className="ml-auto"
                    onClick={() => handleLogin()}
                  >
                    {" "}
                    Logg inn
                  </Button>
                  <br />
                </Paper>

                <Copyright sx={{ pt: 4 }} />
              </Container>
            </Box>
          </ThemeProvider>
        </>
      )}
    </>
  );
}
