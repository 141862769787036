import React from "react";
import logo from "../src/assets/images/Scandic Markiser logo SVG.png";

function Email() {
  return (
    <div className="mailTemplate">
      <h1 className="mailTemplate-title">Logo og fargeprofil</h1>
      <div className="mailTemplate-siteLogo">
        <img src={logo} alt=""/>
        <h2 className="mailTemplate-siteSlogan">
          Standardlogo benyttes med lys bakgrunn
        </h2>
      </div>

      <div className="mailTemplate-order">
        <h3 className="mailTemplate-orderTitle">
          Fargespekter og standardfarger
        </h3>
        <div className="gradientDivider"></div>
        <div className="mailTemplate-orderTotal">
          <h6>
            <span>CMYK:</span> 0,20,100,0
          </h6>
          <h6>
            <span>CMYK:</span> 0,5,100,0
          </h6>
        </div>
        <table className="mailTemplate-orderList">
          <thead>
            <tr>
              <th>Standardfarger</th>
              <th>Beskrivelse</th>
              <th>Farge</th>
              <th>CMYK</th>
              <th>HEX</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Gul</td>
              <td>Varmgul</td>
              <td>
                <span
                  className="colorBox"
                  style={{ background: "#feca0a" }}
                ></span>
              </td>
              <td>0,10,100,0</td>
              <td> #ffc709</td>
            </tr>
            <tr>
              <td>Antrasitt</td>
              <td>RAL 7016</td>
              <td>
                <span
                  className="colorBox"
                  style={{ background: "#16303d" }}
                ></span>
              </td>
              <td>60,30,20,80</td>
              <td> #1b323f</td>
            </tr>
            <tr>
              <td>Gul</td>
              <td>Varmgul</td>
              <td>
                <span
                  className="colorBox"
                  style={{ background: "#9c9998" }}
                ></span>
              </td>
              <td>0,10,100,0</td>
              <td> #ffc709</td>
            </tr>
            <tr>
              <td>Gul</td>
              <td>Varmgul</td>
              <td>
                <span
                  className="colorBox"
                  style={{ background: "#353535" }}
                ></span>
              </td>
              <td>0,10,100,0</td>
              <td> #ffc709</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mailTemplate-footer">
        <h6 className="mailTemplate-footerTitle">Fonter</h6>
        <p>
          <span>Calibri</span> Benyttes i alle trykte og digitale
          markedsmateriell
        </p>
        <p>
          <span>Monotype Corsiva</span> Benyttes i slagord eller sitater
        </p>
      </div>
    </div>
  );
}

export default Email;
