import Typography from "@mui/material/Typography";
import React from "react";
import success from "../assets/images/success1.gif";
import { useTranslation } from "react-i18next";

function Checkouts() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="successMessage">
        <img src={success} alt="success" />
        <Typography variant="h5" className="successMessage__title">
          {t("addOnOrders.thankYou")}
        </Typography>
        <Typography variant="subtitle1" className="successMessage__text">
          {t("addOnOrders.yourOrderNumber")}
        </Typography>
      </div>
    </div>
  );
}

export default Checkouts;
