/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { Box, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import logo from "../../assets/images/Scandic Markiser logo SVG.png";
import { useMsal } from "@azure/msal-react";
import MenuIcon from "@mui/icons-material/Menu";
import "./header.scss";
import LanguageSelect from "../language/LanguageSelect";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const Header = () => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState(window.location.pathname);
  const { instance, accounts } = useMsal();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const name = accounts[0] && accounts[0].name;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLocations(window.location.pathname);
      setLoading(false);
    }, 2000);
  }, [locations]);

  function logout() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      setLoggedIn(false);
    }, 2000);
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar className="siteHeader">
      <Box className="siteHeader__inner">
        <a className="siteHeader__logo" href="/">
          <img src={logo} alt="" className="siteHeader__logoImg" />
        </a>
        <Box className="siteHeader__navbar">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            style={{ display: "none" }}
          >
            <MenuIcon />
          </IconButton>
          <ul className="siteHeader__menu">
            <li>
              {locations.split("/addon-order/")?.[1] ? (
                <Button className="nav-link" href={"/addon-order"} disabled>
                  {t("header.addOnOrder")}
                </Button>
              ) : (
                <Button className="nav-link" href={"/addon-order"}>
                  {t("header.addOnOrder")}
                </Button>
              )}
            </li>
            <li>
              <Button className="nav-link">{t("header.orderList")}</Button>
            </li>
          </ul>
          <Box className="siteHeader__userBlock">
            <h4 className="siteHeader__userName">
              <span>{t("header.Welcome")}</span> {name}
            </h4>
            <Tooltip title="Logg ut">
              <IconButton color="inherit" onClick={logout}>
                <LogoutIcon>Logout</LogoutIcon>
              </IconButton>
            </Tooltip>
          </Box>
          <LanguageSelect />
          {isLoading && (
            <div className="loaderModule">
              <div className="loader">
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__bar"></div>
                <div className="loader__ball"></div>
              </div>
            </div>
          )}
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
