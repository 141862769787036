import { Container } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import bedroom from "./assets/images/bedroom.jpg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import "./home.scss";
import { useTranslation } from "react-i18next";

function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (localStorage.getItem("addOnOrderId")) {
      const addOnOrderId = localStorage.getItem("addOnOrderId");
      navigate(`/addon-order/${addOnOrderId}`);
      localStorage.removeItem("addOnOrderId");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="contentBlock">
        <Container>
          <blockquote> {t("home.welcomeTo")}</blockquote>
        </Container>
      </div>
      {/* <div className="contentMediaBlock">
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={5} xs={12}>
              <div className="contentMediaBlock__text">
                <h6 className="contentMediaBlock__subtitle">VÅRT MÅL</h6>
                <h5 className="contentMediaBlock__title">
                  Vårt mål er å gi deg kvalitet for din investering i
                  solskjerming.
                </h5>
                <p>
                  Velkommen til en ledende bedrift innen innvendig og utvendig
                  solskjerming! Scandic Markiser har siden oppstart i 1995 hatt
                  over 170.000 fornøyde kunder. Vi er stolte over å presentere
                  solskjerming som er norskprodusert og delvis håndlaget.
                </p>
              </div>
            </Grid>
            <Grid item md={7} xs={12}>
              <div className="contentMediaBlock__img">
                <img
                  src={bedroom}
                  className="img-fluid largeImage"
                  alt="blogImage"
                />
                <img
                  src={bedroom}
                  className="img-fluid smallImage"
                  alt="blogImage"
                />
                <button className="roundedBtn">
                  <span>
                    BESTILL BEFARING <TrendingFlatIcon />
                  </span>
                </button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="blogSection">
        <Container>
          <div className="blogSection__heading">
            <h6 className="blogSection__title">BLOGG</h6>
            <h4 className="blogSection__subTitle">
              Siste nytt fra Scandicbloggen.
            </h4>
          </div>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <div className="blogCard">
                <a className="blogCard__img">
                  <img src={bedroom} className="img-fluid" alt="blogImage" />
                  <RemoveRedEyeIcon />
                </a>
                <div className="blogCard__content">
                  <div className="blogCard__info">
                    23.11.2022
                    <div className="blogCard__category">
                      <span>Solskjerming</span>,<span>Varme</span>
                    </div>
                  </div>
                  <h5 className="blogCard__title">
                    <a>
                      Isolerende gardiner kan bidra til å holde varmen i hjemmet
                    </a>
                  </h5>
                  <p>
                    I de kalde månedene kan du holde varmen i hjemmet ved å
                    bruke isolerende gardiner. Les våre…
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className="blogCard">
                <a className="blogCard__img">
                  <img src={bedroom} className="img-fluid" alt="blogImage" />
                  <RemoveRedEyeIcon />
                </a>
                <div className="blogCard__content">
                  <div className="blogCard__info">
                    23.11.2022
                    <div className="blogCard__category">
                      <span>Solskjerming</span>,<span>Varme</span>
                    </div>
                  </div>
                  <h5 className="blogCard__title">
                    <a>
                      Isolerende gardiner kan bidra til å holde varmen i hjemmet
                    </a>
                  </h5>
                  <p>
                    I de kalde månedene kan du holde varmen i hjemmet ved å
                    bruke isolerende gardiner. Les våre…
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div className="blogCard">
                <a className="blogCard__img">
                  <img src={bedroom} className="img-fluid" alt="blogImage" />
                  <RemoveRedEyeIcon />
                </a>
                <div className="blogCard__content">
                  <div className="blogCard__info">
                    23.11.2022
                    <div className="blogCard__category">
                      <span>Solskjerming</span>,<span>Varme</span>
                    </div>
                  </div>
                  <h5 className="blogCard__title">
                    <a>
                      Isolerende gardiner kan bidra til å holde varmen i hjemmet
                    </a>
                  </h5>
                  <p>
                    I de kalde månedene kan du holde varmen i hjemmet ved å
                    bruke isolerende gardiner. Les våre…
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="blogSection__footer">
            <button className="roundedBtn">
              <span>
                Alle artikler <TrendingFlatIcon />
              </span>
            </button>
          </div>
        </Container>
      </div> */}
    </>
  );
}

export default Home;
