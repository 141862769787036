import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import api from "../../api";
import "../header/add-on-orders.scss";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import orderImage from "../../assets/images/Done.jpg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Copyright } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authconfig";

function AddOnOrder() {
  const { t } = useTranslation();
  const params = useParams();

  const [orders, setOrders] = useState<any>([]);
  const [isLoading, setLoading] = React.useState(false);
  const AddOnOrdersId = localStorage.getItem("paramsID");
  const token = localStorage.getItem("token");
  const { instance } = useMsal();
  const mdTheme = createTheme();

  // localStorage.setItem("newId", JSON.stringify(Number(params.id)));

  useEffect(() => {
    setTimeout(() => {
      getAllOrders();
    }, 2000);
  }, [token]);

  const handleLogin = () => {
    instance
      .loginRedirect(loginRequest)
      .then((res: any) => {})
      .catch((error: any) => {});
  };

  const getAllOrders = () => {
    setLoading(true);
    api
      .get(
        `${process.env.REACT_APP_API_URI}api/addonorder/${
          params.id || AddOnOrdersId
        }`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const allOrders = response?.data;
          setOrders(allOrders);
          setLoading(false);
        } else {
          alert("Api Not Working");
        }
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  if (orders?.status === "new") {
    return (
      <>
        {token ? (
          <div className="dataListTable">
            <Container>
              <h3 className="dataListTable__title">
                {t("orders.addOnOrderList")}
              </h3>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Building Type</TableCell>
                      <TableCell align="right">City</TableCell>
                      <TableCell align="right">Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {projects.map((row: any) => {
                return ( */}
                    <>
                      <TableRow
                        key={orders.customerName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {orders?.addonOfferBuilding?.addonOfferProject?.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {orders?.addonOfferBuilding?.buildingName}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {orders.customerCity}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {orders.customerPostalCode}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="View" arrow>
                            <IconButton
                              style={{ color: "#dbaa00" }}
                              href={`/addon-orders/${
                                params.id || AddOnOrdersId
                              }`}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </>
                    {/* );
              })} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          </div>
        ) : (
          <ThemeProvider theme={mdTheme}>
            <CssBaseline />
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography>
                    Du må logge inn for å bruke denne applikasjonen.
                  </Typography>
                  <Button
                    // variant="secondary"
                    className="ml-auto"
                    onClick={() => handleLogin()}
                  >
                    {" "}
                    Logg inn
                  </Button>
                  <br />
                </Paper>

                <Copyright sx={{ pt: 4 }} />
              </Container>
            </Box>
          </ThemeProvider>
        )}
      </>
    );
  } else if (orders?.status === "confirmed") {
    return (
      <Paper
        className="checkboxCard"
        variant="outlined"
        style={{ margin: 0 }}
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <div className="successMessage">
          <img
            src={orderImage}
            alt=""
            style={{
              height: "320px",
              maxWidth: "365px",
              objectFit: "contain",
              width: "100%",
            }}
          />
          <h2 className="successMessage__title">Your Order has been Booked</h2>
        </div>
      </Paper>
    );
  } else {
    // block of code to be executed if the condition1 is false and condition2 is false
  }

  return (
    <div className="dataListTable">
      {/* <Container>{orders?.status === "new" ? "" : ""}</Container> */}
      {isLoading && (
        <div className="loaderModule">
          <div className="loader">
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__ball"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddOnOrder;
