import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import i18n from "../language/i18n";

type language = {
  code: string;
  dispName: string;
};
const langs: language[] = [
  {
    code: "no",
    dispName: "Norwegian",
  },
  {
    code: "en",
    dispName: "English",
  },
];

//const options = [ 'no', 'en'];

export default function SplitButton(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    console.info(`You clicked ${langs[selectedIndex].dispName}`);
  };

  let selectedLanguage = localStorage.getItem("i18nextLng");

  React.useEffect(() => {
    let findIndex = langs.findIndex(
      (option) => option.code === selectedLanguage
    );
    setSelectedIndex(findIndex);
  }, []);

  function handleMenuItemClick(
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) {
    setSelectedIndex(index);
    setOpen(false);
    i18n.changeLanguage(langs[index].code);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  //function ShowFlag({ lang }: { lang: language; }) {
  const ShowFlag = (lang: language, inclName: boolean) => {
    if (lang !== undefined) {
      if (lang.code === "en") {
        if (inclName) {
          return (
            <>
              <span className="fi fi-gb"></span>{" "}
              <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
            </>
          );
        } else {
          return <span className="fi fi-gb"></span>;
        }
      } else if (lang.code === "no") {
        if (inclName) {
          return (
            <>
              <span className="fi fi-no"></span>
              <span style={{ marginLeft: ".5rem" }}>{lang.dispName}</span>
            </>
          );
        } else {
          return <span className="fi fi-no"></span>;
        }
      } else {
        throw Error("Unsuported landguage: " + lang.code);
      }
    }
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
      />

      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        className="langBox"
      >
        {/* <Button onClick={handleClick}></Button> */}
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {ShowFlag(langs[selectedIndex], false)}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="langDropdown"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {langs.map((lang, index) => (
                    <>
                      <MenuItem
                        key={lang.code}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        disabled={index === 2}
                      >
                        {ShowFlag(lang, true)}
                      </MenuItem>
                    </>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
