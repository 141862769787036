import { useEffect, useState } from "react";
import queryString from "query-string";
import { Container } from "@mui/material";
import api from "./api";

function AcceptInvitation() {
  const queryParams = queryString.parse(window.location.search);
  const [acceptInvitation, setAcceptInvitation] = useState<any>([]);

  useEffect(() => {
    getAllAcceptInvitation();
  }, []);

  const getAllAcceptInvitation = () => {
    api
      .get(
        `${process.env.REACT_APP_API_URI}api/customeruser/api/customerusers/acceptinvitation?key=${queryParams.key}`
      )
      .then((response) => {
        // const allInvitation = response?.data?.items;
        // setAcceptInvitation(allInvitation);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  return (
    <div className="contentBlock">
      <Container>
        <blockquote>Welcome to Scandic Markiser Customer Portal !</blockquote>
      </Container>
    </div>
  );
}

export default AcceptInvitation;
