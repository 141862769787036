/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import api from "../../api";
import ImageUploading, { ImageListType } from "react-images-uploading";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Grid, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import orderImage from "../../assets/images/Done.jpg";
import TextField from "@mui/material/TextField";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import DeleteIcon from "@mui/icons-material/Delete";

function AddOnOrders() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [addOnOrderLine, setAddOnOrderLine] = useState<any>([]);
  const [addOnOrderDetails, setAddOnOrderDetails] = useState<any>([]);
  const [value, setValue] = React.useState("");
  const [selectedOffer, setSuniqueDataelectedOffer] = React.useState<any>([]);
  const [isLoading, setLoading] = React.useState(false);
  const [offerData, setOfferData] = useState<any>([]);

  const [buildingImage, setBuildingImage] = React.useState<any>("");
  const [images, setImages] = React.useState<any>([buildingImage]);
  const [offerCounter, setOfferCounter] = useState(0);
  const [selectedOfferId, setSelectedOfferId] = useState(0);
  const [resultData, setResultData] = useState<any>();
  const [newTemp, setNewTemp] = useState<any>([]);

  const [data, setData] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const uniqueData = offerData.filter(
    (value: any, index: number, self: any) => {
      return index === self.findIndex((t: any) => t.id === value.id);
    }
  );

  const maxNumber = 69;

  const defaultData = [
    {
      description: "No selection",
      priceExVat: 0,
    },
  ];

  /**
   * image onChange
   * @param imageList
   * @param addUpdateIndex
   */
  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    setImages(imageList);
  };

  /**
   * norwegianCurrencyFormat
   * @param value
   * @returns
   */
  function getNorwegianCurrencyFormat(value: number) {
    const norwegianCurrencyShortForm = "";
    const norwegianCurrencyFormat = new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
    });
    return norwegianCurrencyFormat
      .formatToParts(value)
      .map(({ type, value }) =>
        type === "currency" ? norwegianCurrencyShortForm : value
      )
      .join("");
  }

  useEffect(() => {
    getAddOnOrders(Number(params.id), 0); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("paramsID", JSON.stringify(Number(params.id))); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProjectBuildingPosition(addOnOrderDetails.addonOfferBuildingId);
    getAllBuildingImage(addOnOrderDetails);
  }, [addOnOrderDetails]);

  let uniqueChars: any = [];
  newTemp.forEach((c: string) => {
    if (!uniqueChars.includes(c)) {
      uniqueChars.push(c);
    }
  });

  const result = newTemp.reduce(
    (total: any, currentValue: any) =>
      (total = total + currentValue.priceExVat),
    0
  );

  const handleDelete = (id: number) => {
    const temp = uniqueChars.filter((i: any) => i.id !== id);
    setNewTemp(temp);
  };

  /**
   * AddOnOderLines
   * @param offer
   */
  const getAddOnOrderLines = (offer: any, label: any) => {
    var data: any = [];
    newTemp.forEach((val: any) => {
      if (
        val?.addonOfferPosition?.positionLabel !==
        offer?.addonOfferPosition?.positionLabel
      ) {
        data.push(val);
      }
    });

    if (offer.description === "No selection") {
      var dummy = newTemp.filter(
        (elem: any) => elem.addonOfferPosition?.positionLabel !== label
      );
      setNewTemp(dummy);
    } else {
      data.push(offer);
      setNewTemp(data);
    }

    // setNewTemp(newTemp);
    setValue(offer.description);
    // setSelectedOffer(offer);

    var positionData = {
      addonOrderId: Number(params.id),
      addonOfferId: offer.id,
    };
    api
      .post(`${process.env.REACT_APP_API_URI}api/addonorderlines`, positionData)
      .then((res) => {
        // setLoading(true);
        setTimeout(() => {
          if (res.status === 201) {
            // setLoading(false);
            getAddOnOrders(Number(params.id), offer.id);
          } else {
            alert("Api Not Working");
          }
        }, 2000);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  /**
   * AddOnOrders
   * @param id
   * @param selectedOfferId
   */
  const getAddOnOrders = (id: any, selectedOfferId: number) => {
    api
      .get(`${process.env.REACT_APP_API_URI}api/addonorder/${id}`)
      .then((response) => {
        const allOrderDetails = response.data;
        const addOnOfferId = response.data.addonOrderLines.map(
          (item: any) => item.addonOfferId
        );
        const filterData = response.data.addonOrderLines.filter(
          ({ addonOfferId }: any, index: number) =>
            !addOnOfferId.includes(addonOfferId, index + 1)
        );
        setAddOnOrderLine(filterData);
        setAddOnOrderDetails(allOrderDetails);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  // const getAllOrder = () => {
  //   api
  //     .get(`${process.env.REACT_APP_API_URI}api/addonorder/${params.id}`, {
  //       withCredentials: true,
  //       headers: { "Access-Control-Allow-Origin": "*" },
  //     })
  //     .then((response) => {
  //       const allAddOnOrders = response?.data;
  //       setAllOrders(allAddOnOrders);
  //     })
  //     .catch((error) => console.error(`Error: ${error}`));
  // };

  /**
   * get ProjectBuildingPosition
   * @param OrderIds
   */
  const getProjectBuildingPosition = (OrderIds: any) => {
    setLoading(true);
    if (OrderIds !== undefined) {
      api
        .get(
          `${process.env.REACT_APP_API_URI}api/addonofferpositions?buildingTypeId=${OrderIds}`
        )
        .then((response) => {
          if (response?.data?.items.length > 0) {
            response?.data?.items.map((offers: any) => {
              api
                .get(
                  `${process.env.REACT_APP_API_URI}api/addonoffers?positionId=${offers.id}`
                )
                .then((response) => {
                  if (response?.data.items) {
                    offers.offers = response?.data.items;
                    setOfferData((oldArray: any) => [...oldArray, offers]);
                    setLoading(false);
                  }
                });
            });
          }
        })
        .catch((error) => console.error(`Error: ${error}`));
    }
  };

  /**
   * BuildingImage
   * @param allOrders
   */
  const getAllBuildingImage = async (addOnOrderDetails: any) => {
    const blobUrl: any = await api.get(
      `${process.env.REACT_APP_API_URI}api/addonofferbuilding/${addOnOrderDetails.addonOfferBuildingId}/image`,
      {
        responseType: "blob",
      }
    );
    var reader = new FileReader();
    reader.readAsDataURL(blobUrl.data);
    reader.onloadend = function () {
      var base64data = reader.result;
      setBuildingImage(base64data);
    };
  };

  const handleCheckProduct = (e: any, offer: any, label: string) => {
    newTemp[label] = offer.id;

    if (newTemp?.description === "No selection") {
      setNewTemp(offer.id);
    }

    if (e.target.checked === false) {
      setNewTemp(newTemp.filter((elem: string) => elem !== offer.id));
    }
    setSelectedOfferId(offer.id);
    getAddOnOrderLines(offer, label);
  };

  const handleCheckOut = () => {
    setLoading(true);
    // setActiveStep(activeStep + 1);
    api
      .post(
        `${process.env.REACT_APP_API_URI}api/addonorder/${localStorage.getItem(
          "paramsID"
        )}/placeorder`
      )
      .then((res) => {
        setLoading(true);
        setTimeout(() => {
          if (res.status === 204) {
            navigate(`/checkout`);
            setLoading(false);
          } else {
            alert("api not calling");
          }
        }, 2000);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  if (addOnOrderDetails?.status === "new") {
    return (
      <div className="orderDetail">
        <Container maxWidth="lg">
          <div className="orderDetail-body">
            <Grid container spacing={4}>
              <Grid item xs={12} md={7} lg={8}>
                <div className="orderOptions">
                  {buildingImage && (
                    <ImageUploading
                      multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper">
                          {imageList.map((image: any, index: any) => {
                            return (
                              <>
                                <div key={index} className="image-item">
                                  <img
                                    src={
                                      image.dataURL
                                        ? image.dataURL
                                        : buildingImage
                                    }
                                    alt="buildingImage"
                                    className="img-fluid"
                                  />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      )}
                    </ImageUploading>
                  )}

                  <div className="orderMainTitle">
                    {addOnOrderDetails?.addonOfferBuilding?.buildingName}
                  </div>
                  {/* {!open ? ( */}
                  <FormControl className="w-100" style={{ width: "100%" }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group- "
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={value === "" ? "No selection" : value}
                      ng-checked="true"
                    >
                      {uniqueData.length > 0 &&
                        uniqueData.map((item: any, index: number) => {
                          return index === offerCounter ? (
                            <>
                              <div className="orderOptions-header">
                                <div style={{ minWidth: "30px" }}>
                                  {offerCounter !== 0 ? (
                                    <button>
                                      <ArrowBackIosNewIcon
                                        onClick={() =>
                                          setOfferCounter(offerCounter - 1)
                                        }
                                      ></ArrowBackIosNewIcon>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <h1>{item?.positionLabel}</h1>
                                <div style={{ minWidth: "30px" }}>
                                  {offerCounter <= uniqueData.length ? (
                                    <button>
                                      <ArrowForwardIosIcon
                                        onClick={() => {
                                          setOfferCounter(offerCounter + 1);
                                        }}
                                      />
                                    </button>
                                  ) : (
                                    <>
                                      {/* <button>
                                          <ArrowForwardIosIcon
                                            onClick={handleOpen}
                                          />
                                        </button> */}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="orderOptions-list">
                                {defaultData
                                  .concat(item?.offers)
                                  .map((offer: any) => {
                                    return (
                                      <>
                                        <FormControlLabel
                                          control={<Radio />}
                                          onClick={(e: any) => {
                                            handleCheckProduct(
                                              e,
                                              offer,
                                              item?.positionLabel
                                            );
                                          }}
                                          checked={
                                            data[item?.positionLabel]
                                            // newTemp[item?.positionLabel] ===
                                            //   offer.id &&
                                            // newTemp[item?.positionLabel]
                                          }
                                          value={offer.description}
                                          label={
                                            <div className="orderOptions-ListCard">
                                              {offer.description}
                                              <div className="orderOptions-ListCard-price">
                                                {getNorwegianCurrencyFormat(
                                                  offer.priceExVat
                                                )}
                                              </div>
                                            </div>
                                          }
                                        />
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          ) : (
                            ""
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                  {/* ) : ( */}
                  <>
                    {offerCounter !== uniqueData.length ? (
                      ""
                    ) : (
                      <>
                        <div className="orderOptions-header">
                          <button>
                            <ArrowBackIosNewIcon
                              onClick={() => setOfferCounter(offerCounter - 1)}
                            ></ArrowBackIosNewIcon>
                          </button>{" "}
                          <h1>Confirmation</h1>
                          <div style={{ minWidth: "30px" }}></div>
                        </div>
                        <div
                          className="checkboxCard"
                          style={{ marginTop: "22px" }}
                        >
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="Name"
                                value={addOnOrderDetails?.customerName}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="Last Name"
                                value={addOnOrderDetails?.customerLastName}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                value={addOnOrderDetails?.customerAddress1}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="Address2"
                                value={addOnOrderDetails?.customerAddress2}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="City"
                                value={addOnOrderDetails?.customerCity}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="PostalCode"
                                value={addOnOrderDetails?.customerPostalCode}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </div>
                        <div className="checkoutFooter">
                          <p>{t("addOnOrders.byConfirming")}</p>
                          <p>{t("addOnOrders.whenProcessed")}</p>
                          <p>{t("addOnOrders.afterOrder")}</p>
                          <div
                            style={{ textAlign: "center", marginTop: "30px" }}
                          >
                            <Button
                              style={{ maxWidth: "155px" }}
                              onClick={handleCheckOut}
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                        {isLoading && (
                          <div className="loaderModule">
                            <div className="loader">
                              <div className="loader__bar"></div>
                              <div className="loader__bar"></div>
                              <div className="loader__bar"></div>
                              <div className="loader__bar"></div>
                              <div className="loader__bar"></div>
                              <div className="loader__ball"></div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                  {/* )} */}
                </div>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <div className="orderListBox">
                  <h3 className="orderListBox-title">
                    {t("addOnOrders.shoppingCart")}
                  </h3>
                  <div className="orderListBox-list">
                    {/* {value === "" || value === "No selection" ? null : ( */}
                    <>
                      {/* {addOnOrderLine.map((item: any) => {
                          return (
                            <div className="shoppingCardList">
                              <div>
                                <h4 className="shoppingCardList-subTitle">
                                  {item.addonOfferPositionLabel}
                                </h4>
                                <h5 className="shoppingCardList-title">
                                  {item.addonOfferDescription}
                                </h5>
                              </div>
                              <span className="shoppingCardList-price">
                                {getNorwegianCurrencyFormat(
                                  item.addonOfferPriceExVat
                                )}
                              </span>
                            </div>
                          );
                        })} */}
                      {/* <div className="shoppingCardList">
                          <div>
                            <h4 className="shoppingCardList-subTitle">
                              {selectedOffer?.addonOfferPosition?.positionLabel}
                            </h4>
                            <h5 className="shoppingCardList-title">
                              {selectedOffer?.description}
                            </h5>
                          </div>
                          <span className="shoppingCardList-price">
                            {getNorwegianCurrencyFormat(
                              selectedOffer?.priceExVat
                            )}
                          </span>
                        </div> */}

                      {uniqueChars.length > 0 &&
                        uniqueChars.map((item: any) => {
                          if (item.description === "No selection") {
                            return handleDelete(item.id);
                          } else {
                            return (
                              <div className="shoppingCardList">
                                <div>
                                  <h4 className="shoppingCardList-subTitle">
                                    {item?.addonOfferPosition?.positionLabel}
                                  </h4>
                                  <h5 className="shoppingCardList-title">
                                    {item?.description}
                                  </h5>
                                </div>
                                <span className="shoppingCardList-price">
                                  {getNorwegianCurrencyFormat(item?.priceExVat)}
                                  <DeleteIcon
                                    onClick={() => handleDelete(item.id)}
                                  />
                                </span>
                              </div>
                            );
                          }
                        })}
                    </>
                    {/* )} */}
                  </div>

                  <div className="orderListBox-footer">
                    {/* {value === "" || value === "No selection" ? null : ( */}
                    <div className="orderListBox-total">
                      {t("addOnOrders.totalPrice")} ={" "}
                      <span>{getNorwegianCurrencyFormat(result)}</span>
                    </div>
                    {/* )} */}
                    {/* {value === "" || value === "No selection" ? null : (
                        <Button
                          onClick={() => {
                            setLoading(true);
                            setTimeout(() => {
                              setLoading(false);
                              navigate(`/checkout`, {
                                state: addOnOrderDetails,
                              });
                            }, 2000);
                          }}
                          className="checkoutBtn"
                        >
                          {t("addOnOrders.checkout")}
                        </Button>
                      )} */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  } else if (addOnOrderDetails?.status === "confirmed") {
    return (
      <Paper
        className="checkboxCard"
        variant="outlined"
        style={{ margin: 0 }}
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <div className="successMessage">
          <img
            src={orderImage}
            alt=""
            style={{
              height: "320px",
              maxWidth: "365px",
              objectFit: "contain",
              width: "100%",
            }}
          />
          <h2 className="successMessage__title">Your Order has been Booked</h2>
        </div>
      </Paper>
    );
  } else {
    // block of code to be executed if the condition1 is false and condition2 is false
  }

  return (
    <>
      {isLoading && (
        <div className="loaderModule">
          <div className="loader">
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__bar"></div>
            <div className="loader__ball"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddOnOrders;
